import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { AlertContext } from '../../context/AlertProvider';
import { useAuth } from '../../hooks/useAuth';
import { Alert } from '../atoms/Alert';
import { Block } from '../atoms/Block';
import { LinkButton, PrimaryButton } from '../atoms/Button';
import { CheckBoxWithLabel } from '../molecules/CheckBoxWithLabel';
import { InputFormWithLabel } from '../molecules/InputFormWithLabel';
import { RadioButtonWithLabel } from '../molecules/RadioButtonWithLabel';
import { SelectWithLabel } from '../molecules/SelectWithLabel';
import { Modal } from '../organisms/Modal';
import { HeaderProps, RowProps, Table, TableProps } from '../organisms/Table';
import { PageTemplate } from '../templates/PageTemplate';

const Heading = ({ text, addClass }: { text: string; addClass?: string }) => {
  return (
    <div className={`text-center ${addClass ?? ''}`}>
      <h6 className="text-ms font-bold dark:text-white">{text}</h6>
    </div>
  );
};

export const Home = () => {
  // 検索API用の検索条件の型定義/初期化
  type SearchReqType = {
    number: string | undefined;
    ward: string | undefined;
    work: string | undefined;
    train: 'on' | undefined;
    driver: 'on' | undefined;
    conductor: 'on' | undefined;
    drive_day: 0 | 1;
  };
  const initialReqData: SearchReqType = {
    number: undefined,
    ward: undefined,
    work: undefined,
    train: 'on',
    driver: 'on',
    conductor: 'on',
    drive_day: 0,
  };
  // 検索用パラメータのuseState
  const [reqData, setReqData] = useState<SearchReqType>(initialReqData);
  // 検索結果を保持するuseState
  // ToDo: 検索結果の型定義 一旦any
  const [searchRes, setSearchRes] = useState<any>();
  // 詳細画面表示中の運用種別を保持するuseState
  const [detailKind, setDetailKind] = useState<
    'train' | 'driver' | 'conductor' | undefined
  >(undefined);
  // ToDo: 検索結果の型定義 一旦any
  const [detailRes, setDetailRes] = useState<any>();
  // 読み込み中表示用のuseState
  const [loading, setLoading] = useState<boolean>(false);
  // テーブル用のuseState
  const initialTrainDriverHeader: HeaderProps[] = [
    {
      cellProps: [
        { text: '列番', className: 'px-1 py-3' },
        { text: '発駅', className: 'pe-1 py-3' },
        { text: '着駅', className: 'pe-1 py-3' },
        { text: '区所', className: 'pe-1 py-3' },
        { text: '仕業', className: 'pe-1 py-3' },
        { text: '形式', className: 'pe-1 py-3' },
        { text: '両数', className: 'py-3' },
      ],
    },
  ];
  const initialConductorHeader: HeaderProps[] = [
    {
      cellProps: [
        { text: '列番', className: 'px-1 py-3' },
        { text: '発駅', className: 'pe-1 py-3' },
        { text: '着駅', className: 'pe-1 py-3' },
        { text: '区所', className: 'pe-1 py-3' },
        { text: '行路', className: 'pe-1 py-3' },
        { text: '形式', className: 'pe-1 py-3' },
        { text: '両数', className: 'py-3' },
      ],
    },
  ];
  const initialRow: RowProps[] = [];
  const initialTrainDriverTableState: TableProps = {
    headerProps: initialTrainDriverHeader,
    rowProps: initialRow,
  };
  const initialConductorTableState: TableProps = {
    headerProps: initialConductorHeader,
    rowProps: initialRow,
  };
  const [trainTableState, setTrainTableState] = useState<TableProps>(
    initialTrainDriverTableState
  );
  const [driverTableState, setDriverTableState] = useState<TableProps>(
    initialTrainDriverTableState
  );
  const [conductorTableState, setConductorTableState] = useState<TableProps>(
    initialConductorTableState
  );

  // モーダル関連のuseState
  const [openResultModal, setOpenResultModal] = useState<boolean>(false);
  const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);
  // 区所一覧のuseState
  const [wardList, setWardList] = useState<string[]>([]);

  // アラートメッセージのContext
  const { setAlert } = useContext(AlertContext)!;
  // ログアウト用のカスタムフック
  const { logout } = useAuth();

  // 初期表示時に区所一覧取得APIを投げる
  useEffect(() => {
    (async () => {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/wards`)
        .then((res) => {
          setWardList(res.data.wards);
        })
        .catch(() => {
          // エラーが発生しても、特に何もしない (区所指定できなくなるだけ)。
        });
    })();
  }, []);
  const wardTextList = ['選択してください', ...wardList];
  const wardValueList = ['', ...wardList];

  // フォーム入力値変化時のイベントハンドラ
  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = event.target;
    setReqData((prevData) => ({
      ...prevData,
      [name]: (type === 'checkbox' && !checked) || !value ? undefined : value,
    }));
  };
  // 選択フォーム変化時のイベントハンドラ
  const selectChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setReqData((prevData) => ({
      ...prevData,
      [name]: value || undefined,
    }));
  };

  // 検索時ボタン押下時のイベントハンドラ
  const searchEventHandler = async () => {
    // 入力内容検証
    const validation = () => {
      // 列番、区所、行路番号のいずれかが入力されているか検証
      if (!reqData.number && !reqData.ward && !reqData.work) {
        setAlert({
          color: 'failure',
          msg: '列番、区所、行路番号のいずれかは必ず入力してください。',
        });
        return false;
      }

      // 表示内容のいずれかは選択されているか検証
      if (!reqData.train && !reqData.driver && !reqData.conductor) {
        setAlert({
          color: 'failure',
          msg: '表示内容のいずれかは選択してください。',
        });
        return false;
      }

      return true;
    };

    // 入力検証 不正項目があった場合、処理を中断
    if (!validation()) {
      return;
    }

    // 検索APIをリクエスト
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/search`, {
        params: { ...reqData },
      })
      .then((res) => {
        setAlert({
          color: res.data.status,
          msg: res.data.message,
        });
        setSearchRes(res.data);
        setOpenResultModal(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          logout({});
        } else {
          setAlert({
            color: 'failure',
            msg: `エラーが発生しました。[${
              error.data?.message ?? error.message ?? '予期せぬエラー'
            }]`,
          });
        }
      });
    setLoading(false);
  };

  // 運用詳細取得関数
  const getOperationDetail = async ({
    kind,
    id,
    day,
  }: {
    kind: 'train' | 'driver' | 'conductor';
    id: string;
    day?: 'next' | 'prev' | undefined;
  }) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/${kind}/${id}`, {
        params: { day },
      })
      .then((res) => {
        setDetailRes(res.data.data);
        setOpenDetailModal(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          logout({});
        } else {
          setAlert({
            color: 'failure',
            msg: `エラーが発生しました。[${
              error.data?.message ?? error.message ?? '予期せぬエラー'
            }]`,
          });
        }
      });
    setDetailKind(kind);
    setLoading(false);
  };

  // 検索結果表示Table用のuseState
  useEffect(() => {
    // 検索結果に車両運用が含まれている場合、車両運用Table用のuseStateを更新
    if (searchRes?.trains) {
      // 行押下時のイベントハンドラ
      const rowClickHandler = (
        event: React.MouseEvent<HTMLTableRowElement>
      ) => {
        // 押下された運用のID
        // eslint-disable-next-line prefer-destructuring
        const id = event.currentTarget.id;
        // 運用詳細を取得
        getOperationDetail({ kind: 'train', id });
      };

      const rowState: RowProps[] = [];
      searchRes.trains.forEach((train: any) => {
        const carIndex =
          train.index !== 0
            ? ` (${(train.index / 10).toFixed(0)}-${train.index % 10})`
            : '';
        const cars = `${train.cars}両${carIndex}`;
        rowState.push({
          cellProps: [
            {
              bold: true,
              text: train.full_train_num,
              className: 'px-1 py-3 text-xs font-medium',
            },
            { text: train.start, className: 'pe-1 py-3 text-xs' },
            { text: train.goal, className: 'pe-1 py-3 text-xs' },
            { text: train.ward, className: 'pe-1 py-3 text-xs' },
            { text: train.work, className: 'pe-1 py-3 text-xs' },
            { text: train.model, className: 'pe-1 py-3 text-xs' },
            { text: cars, className: 'py-3 text-xs' },
          ],
          onClick: rowClickHandler,
          id: train.id,
        });
      });
      setTrainTableState((prevState) => ({
        ...prevState,
        rowProps: rowState,
      }));
    }

    // 検索結果に運転士運用が含まれている場合、運転士運用Table用のuseStateを更新
    if (searchRes?.drivers) {
      // 行押下時のイベントハンドラ
      const rowClickHandler = (
        event: React.MouseEvent<HTMLTableRowElement>
      ) => {
        // 押下された運用のID
        // eslint-disable-next-line prefer-destructuring
        const id = event.currentTarget.id;
        // 運用詳細を取得
        getOperationDetail({ kind: 'driver', id });
      };

      const rowState: RowProps[] = [];
      searchRes.drivers.forEach((driver: any) => {
        const work = `${driver.day === 0 ? '' : '△'}${driver.work}`;
        rowState.push({
          cellProps: [
            {
              bold: true,
              text: driver.full_train_num,
              className: 'px-1 py-3 text-xs font-medium',
            },
            {
              text: driver.start,
              className: 'pe-1 py-3 text-xs',
            },
            { text: driver.goal, className: 'pe-1 py-3 text-xs' },
            { text: driver.ward, className: 'pe-1 py-3 text-xs' },
            { text: work, className: 'pe-1 py-3 text-xs' },
            { text: driver.model, className: 'py-3 text-xs' },
            { text: `${driver.cars}両`, className: 'py-3 text-xs' },
          ],
          onClick: rowClickHandler,
          id: driver.id,
        });
      });
      setDriverTableState((prevState) => ({
        ...prevState,
        rowProps: rowState,
      }));
    }

    // 検索結果に車掌運用が含まれている場合、車掌運用Table用のuseStateを更新
    if (searchRes?.conductors) {
      // 行押下時のイベントハンドラ
      const rowClickHandler = (
        event: React.MouseEvent<HTMLTableRowElement>
      ) => {
        // 押下された運用のID
        // eslint-disable-next-line prefer-destructuring
        const id = event.currentTarget.id;
        // 運用詳細を取得
        getOperationDetail({ kind: 'conductor', id });
      };

      const rowState: RowProps[] = [];
      searchRes.conductors.forEach((conductor: any) => {
        const work = `${conductor.day === 0 ? '' : '△'}${conductor.work}`;
        rowState.push({
          cellProps: [
            {
              bold: true,
              text: conductor.full_train_num,
              className: 'px-1 py-3 text-xs font-medium',
            },
            {
              text: conductor.start,
              className: 'pe-1 py-3 text-xs',
            },
            {
              text: conductor.goal,
              className: 'pe-1 py-3 text-xs',
            },
            {
              text: conductor.ward,
              className: 'pe-1 py-3 text-xs',
            },
            {
              text: work,
              className: 'pe-1 py-3 text-xs',
            },
            {
              text: conductor.model,
              className: 'py-3 text-xs',
            },
            {
              text: `${conductor.cars}両`,
              className: 'py-3 text-xs',
            },
          ],
          onClick: rowClickHandler,
          id: conductor.id,
        });
      });
      setConductorTableState((prevState) => ({
        ...prevState,
        rowProps: rowState,
      }));
    }
  }, [searchRes]);

  // 次/前運用表示ボタン押下時のイベントハンドラ
  const prevButtonHandler = () => {
    // detailKindがundefinedの場合return
    if (!detailKind) {
      return;
    }
    // 運用詳細を取得
    getOperationDetail({ id: detailRes[0]?.id, kind: detailKind, day: 'prev' });
  };
  const nextButtonHandler = () => {
    // detailKindがundefinedの場合return
    if (!detailKind) {
      return;
    }
    // 運用詳細を取得
    getOperationDetail({ id: detailRes[0]?.id, kind: detailKind, day: 'next' });
  };

  // 運用が見つからなかった場合のアラートメッセージ
  const notFoundAlert: JSX.Element = (
    <Alert
      message="運用が見つかりませんでした。"
      color="light"
      dismissable={false}
    />
  );

  const prevTextMapping = {
    train: '前日',
    driver: '前仕業',
    conductor: '前行路',
  };

  const nextTextMapping = {
    train: '翌日',
    driver: '次仕業',
    conductor: '次行路',
  };

  // 運用詳細の前日/翌日ボタン表示部
  const prevAndNextButtonArea: JSX.Element = (
    <div className="flex justify-between">
      <LinkButton
        text={prevTextMapping[detailKind ?? 'train']}
        onClick={prevButtonHandler}
      />
      <LinkButton
        text={nextTextMapping[detailKind ?? 'train']}
        onClick={nextButtonHandler}
      />
    </div>
  );

  return (
    <PageTemplate headerText="クイック検索" loading={loading}>
      <div className="wrapper-search-conditions">
        <p className="mb-4 text-center">
          検索条件を入力してください。
          <br />
          列番だけ、行路番号だけでもおっけーです👌
        </p>
        <InputFormWithLabel
          formName="number"
          labelText="列車番号"
          onChange={inputChangeHandler}
        />
        <div className="grid grid-cols-2 gap-4 pb-4">
          <SelectWithLabel
            name="ward"
            optionText={wardTextList}
            optionValue={wardValueList}
            disabled={!wardList.length}
            labelText="区所"
            onChange={selectChangeHandler}
          />
          <InputFormWithLabel
            formName="work"
            labelText="行路/仕業"
            onChange={inputChangeHandler}
          />
          <div>
            <h5>表示内容</h5>
            <div className="border rounded-lg p-2">
              <CheckBoxWithLabel
                name="train"
                value="on"
                labelText="車　両 (A運用)"
                checked
                onChange={inputChangeHandler}
              />
              <CheckBoxWithLabel
                name="driver"
                value="on"
                labelText="運転士 (B運用)"
                checked
                onChange={inputChangeHandler}
              />
              <CheckBoxWithLabel
                name="conductor"
                value="on"
                labelText="車　掌 (C運用)"
                checked
                onChange={inputChangeHandler}
              />
            </div>
          </div>
          <div>
            <h5>運転日</h5>
            <div className="border rounded-lg p-2">
              <RadioButtonWithLabel
                name="drive_day"
                radioButtonId="weekday"
                value="0"
                labelText="平日"
                checked
                onChange={inputChangeHandler}
              />
              <RadioButtonWithLabel
                name="drive_day"
                radioButtonId="holiday"
                value="1"
                labelText="休日"
                onChange={inputChangeHandler}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <PrimaryButton
            text="検索"
            id="search"
            addClass={['w-2/5']}
            onClick={searchEventHandler}
          />
        </div>
      </div>
      {/* 検索結果表示モーダル */}
      <Modal
        name="search-result-modal"
        openModal={openResultModal}
        setOpenModalBoolean={setOpenResultModal}
        header={<Heading text="検索結果" />}
        headerClassName="px-3 py-3"
        bodyClassName="p-2"
        overlay={!openDetailModal && !loading}
        staticModal
        footerClassName="justify-end px-4 py-2"
      >
        <div className="wrapper-train-search-result">
          {/* 車両運用一覧表示部分 */}
          {reqData.train && (
            <>
              <Heading text="車両運用 (A運用)" />
              {searchRes?.trains?.length ? (
                <Table {...trainTableState} />
              ) : (
                notFoundAlert
              )}
            </>
          )}
        </div>
        <div className="wrapper-driver-search-result">
          {/* 運転士運用一覧表示部分 */}
          {reqData.driver && (
            <>
              <Heading text="運転士運用 (B運用)" />
              {searchRes?.drivers?.length ? (
                <Table {...driverTableState} />
              ) : (
                notFoundAlert
              )}
            </>
          )}
        </div>
        <div className="wrapper-conductor-search-result">
          {/* 車掌運用一覧表示部分 */}
          {reqData.conductor && (
            <>
              <Heading text="車掌運用 (C運用)" />
              {searchRes?.conductors?.length ? (
                <Table {...conductorTableState} />
              ) : (
                notFoundAlert
              )}
            </>
          )}
        </div>
      </Modal>
      {/* 運用詳細表示モーダル */}
      <Modal
        name="show-detail-modal"
        openModal={openDetailModal}
        setOpenModalBoolean={setOpenDetailModal}
        size="3xl"
        header={<Heading text="運用詳細" />}
        headerClassName="px-3 py-3"
        overlay={openDetailModal}
        staticModal
        footerClassName="justify-end px-4 py-2"
      >
        <div className="wrapper-operation-detail-result">
          {detailKind && (
            <>
              <Heading
                text={
                  detailKind === 'train' || detailKind === 'driver'
                    ? `${detailRes[0].ward} ${detailRes[0].work}仕業`
                    : `${detailRes[0].ward} ${detailRes[0].work}行路`
                }
                addClass="mb-3"
              />
              {prevAndNextButtonArea}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
                {detailRes?.map((ope: any, index: number) => {
                  return (
                    <Block shadow={false} addClass="sm:p-2 my-0 mb-2 lg:p-2">
                      <>
                        <Heading
                          text={`#${index + 1}. ${ope?.full_train_num}`}
                          addClass="mb-2"
                        />
                        {/* 乗務(運転)区間表示部テーブル */}
                        <Table
                          {...{
                            rowProps: [
                              {
                                cellProps: [
                                  {
                                    text: ope.start,
                                    className:
                                      'text-center text-base px-2 py-1 font-semibold text-gray-700',
                                  },
                                  {
                                    text: '→',
                                    className:
                                      'text-center text-base px-2 py-1 font-semibold text-gray-700',
                                    rowSpan: 2,
                                  },
                                  {
                                    text: ope.goal,
                                    className:
                                      'text-center text-base px-2 py-1 font-semibold text-gray-700',
                                  },
                                ],
                              },
                              {
                                cellProps: [
                                  {
                                    text: `${
                                      ope.dep_time.slice(0, -2) === ''
                                        ? '0'
                                        : ope.dep_time.slice(0, -2)
                                    }:${ope.dep_time.slice(-2)}発`,
                                    className:
                                      'text-center px-2 pb-1 text-gray-700',
                                  },
                                  {
                                    text: `${
                                      ope.arr_time.slice(0, -2) === ''
                                        ? '0'
                                        : ope.arr_time.slice(0, -2)
                                    }:${ope.arr_time.slice(-2)}着`,
                                    className:
                                      'text-center px-2 pb-1 text-gray-700',
                                  },
                                ],
                              },
                            ],
                            hover: false,
                            border: false,
                            shadow: false,
                          }}
                        />
                        {/* 形式・両数表示部 編成位置表示(車両運用時のみ) */}
                        <>
                          <p className="w-full text-center text-base text-gray-700 mb-2">
                            {`${ope.model}系 ${ope.cars}両`}
                          </p>
                          {/* 編成位置表示 */}
                          {detailKind === 'train' &&
                            ope.index !== 0 &&
                            (() => {
                              const carList: JSX.Element[] = [];

                              for (let i = ope.cars; i > 0; i -= 1) {
                                if (
                                  Math.floor(ope.index / 10) <= i &&
                                  i <= ope.index % 10
                                ) {
                                  carList.push(
                                    <span
                                      className={`font-mono bg-blue-700 text-xs text-white font-medium mr-0.5 px-2 rounded-sm${
                                        i === 1 ? ' rounded-tr-lg' : ''
                                      }	dark:bg-blue-900 dark:text-blue-300`}
                                    >
                                      {i}
                                    </span>
                                  );
                                } else {
                                  carList.push(
                                    <span
                                      className={`font-mono bg-gray-400 text-xs text-white font-medium mr-0.5 px-2 rounded-sm${
                                        i === 1 ? ' rounded-tr-lg' : ''
                                      }	dark:bg-gray-700 dark:text-gray-300`}
                                    >
                                      {i}
                                    </span>
                                  );
                                }
                              }
                              return (
                                <Block
                                  shadow={false}
                                  addClass="sm:p-2 my-0 mb-2 lg:p-2"
                                >
                                  <p className="text-center text-sm text-gray-700">
                                    編成位置
                                  </p>
                                  <div className="text-center">
                                    {[...carList]}
                                  </div>
                                  <p className="text-xs text-right text-gray-700 pr-3">{`${ope.goal}→`}</p>
                                </Block>
                              );
                            })()}
                        </>
                      </>
                    </Block>
                  );
                })}
              </div>
              {prevAndNextButtonArea}
            </>
          )}
        </div>
      </Modal>
    </PageTemplate>
  );
};
