import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AlertContext } from '../../context/AlertProvider';
import { PageTemplate } from '../templates/PageTemplate';
import { PrimaryButton, DangerButton } from '../atoms/Button';
import { Block } from '../atoms/Block';
import { Text } from '../atoms/Text';
import { Heading } from '../atoms/Heading';
import { SelectWithLabel } from '../molecules/SelectWithLabel';
import { InputFileWithLabel } from '../molecules/InputFileWithLabel';

export const Admin = () => {
  // ファイル更新APIの型定義/初期化
  type UpdateReqType = {
    drive_day: string;
    file: File | null;
  };
  const initialUpdateReqData: UpdateReqType = {
    drive_day: '',
    file: null,
  };
  // ファイル更新APIのリクエストパラメータのuseState
  const [updateReqData, setUpdateReqData] =
    useState<UpdateReqType>(initialUpdateReqData);
  // 読み込み中表示用のuseState
  const [loading, setLoading] = useState<boolean>(false);

  // アラートメッセージのContext
  const { setAlert } = useContext(AlertContext)!;

  // 選択フォーム変化時のイベントハンドラ
  const selectChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setUpdateReqData((prevData) => ({
      ...prevData,
      [name]: value || undefined,
    }));
  };

  // 選択ファイル変化時のイベントハンドラ
  const uploadDataHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;
    if (!files || files?.length === 0) return;
    const file = files[0];
    setUpdateReqData((prevData) => ({
      ...prevData,
      file,
    }));
  };

  // 削除ボタン押下時のイベントハンドラ
  const deleteButtonHandler = async () => {
    setLoading(true);
    await axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/api/admin/setting`)
      .then(() => {
        setAlert({
          color: 'success',
          msg: 'データを消去しました',
        });
      })
      .catch((error) => {
        setAlert({
          color: 'failure',
          msg: `エラーが発生しました。[${
            error.data?.message ?? error.message ?? '予期せぬエラー'
          }]`,
        });
      });
    setLoading(false);
  };

  // 更新ボタン押下時のイベントハンドラ
  const updateButtonHandler = async () => {
    // 入力検証
    const validation = () => {
      if (!updateReqData.drive_day) {
        setAlert({
          color: 'failure',
          msg: '運転日を選択してください。',
        });
        return false;
      }
      if (!updateReqData.file) {
        setAlert({
          color: 'failure',
          msg: 'ファイルを選択してください。',
        });
        return false;
      }
      return true;
    };
    // 入力検証結果がNGの場合、return
    if (!validation()) return;

    setLoading(true);
    const form = new FormData();

    form.append('file', updateReqData.file as Blob);
    form.append('drive_day', updateReqData.drive_day.toString());

    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/setting`, form)
      .then(() => {
        setAlert({
          color: 'success',
          msg: 'データを登録しました',
        });
      })
      .catch((error) => {
        setAlert({
          color: 'failure',
          msg: `エラーが発生しました。[${
            error.data?.message ?? error.message ?? '予期せぬエラー'
          }]`,
        });
      });
    setLoading(false);
  };

  return (
    <PageTemplate headerText="データ更新 (管理者向け)" loading={loading}>
      <Block>
        <Heading.H5 text="データ消去" />
        <Text>
          現在登録されているデータを消去します。
          <br />
          データ更新時は、データの消去→データ登録の順に作業を行ってください。
        </Text>
        <div className="flex justify-center place-items-center relative">
          <DangerButton
            type="button"
            text="消去"
            addClass={['w-32']}
            onClick={deleteButtonHandler}
          />
        </div>
      </Block>
      <Block>
        <Heading.H5 text="データ登録" />
        <Text>
          CSVファイルをアップロードし更新データを登録します。
          <br />
          データの登録にはしばらく時間がかかる場合があります。
          絶対にローディング画面を閉じないでください。
        </Text>
        <SelectWithLabel
          name="drive_day"
          optionText={['選択してください', '平日', '休日']}
          optionValue={['', '0', '1']}
          labelText="運転日"
          onChange={selectChangeHandler}
        />
        <InputFileWithLabel
          formName="file"
          labelText="データファイル"
          onChange={uploadDataHandler}
        />

        <div className="flex justify-center place-items-center relative">
          <PrimaryButton
            type="button"
            text="更新"
            addClass={['w-32']}
            onClick={updateButtonHandler}
          />
        </div>
      </Block>
    </PageTemplate>
  );
};
